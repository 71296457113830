.Login {
    text-align: center;
}

.login-form {
    width: 300px;
    margin: 0 auto;
    padding: 30px 0;
    font-family: 'Roboto', sans-serif;
}

.login-form input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.login-form button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}
